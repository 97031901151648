.navigation-title {
  @apply text-xs lg:text-sm xl:text-lg font-semibold mb-2;
}

.navigation-list {
  @apply text-xs lg:text-sm xl:text-lg text-black;
}

.copyright {
  @apply font-semibold my-4 text-xs lg:text-sm xl:text-lg;
}
