.nav-link {
  @apply rounded-md px-3 py-2 text-sm xl:text-lg font-medium;
}

.underline-animation {
  position: relative;
}

.underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fa1e0e;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.4s ease-out;
}

.underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.underline-animation:active::after {
  background-color: #f5f5f5;
}
